//------------------------------------------------------------------------------------

.payment-screen {
    height: 100vh;
    width: 100vw;

    .screen-title {
        padding: var(--space);
        color: var(--primary);
    }

    .steps {
        color: var(--secondary);
        padding: 0 var(--space) var(--space) var(--space);
    }

    .note-text {
        padding: 0 var(--space) var(--space) var(--space);
        color: var(--on-surface-variant);
    }

    .amount-text {
        padding: 0 var(--space) var(--space) var(--space);
        color: var(--on-surface-variant);
    }

    .button-container {
        padding: 0 var(--space) var(--space) var(--space);

        .filled-tonal-button {
            margin: 0 0 0 var(--space);
        }
    }

    .border {
        margin: 0 var(--space) var(--space) var(--space);
        border-bottom: 1px solid var(--outline);
    }

    .qr-container {
        display: flex;
        padding: 0 var(--space);
        gap: var(--space);

        .instructions-container {
            .instructions {
                color: var(--on-surface-variant);
                margin: 0 0 var(--space) 0;
            }

            li {
                margin: 0 0 var(--space) 0;
                color: var(--on-surface);
            }
        }

        img {
            width: 300px;
            box-shadow: 0 1px 3px var(--shadow);
        }
    }
}

//------------------------------------------------------------------------------------

@media screen and (max-width: 600px) {
    .payment-screen {
        .qr-container {
            flex-direction: column;

            img {
                width: calc(100vw - (var(--space) * 2));
            }
        }
    }
}

//------------------------------------------------------------------------------------