//------------------------------------------------------------------------------------

.side-navigation {
	height: calc(100vh - 64px);
	width: 260px;
	background-color: var(--background);
	padding: calc(var(--space) - 8px);
	position: sticky;
	top: 64px;
	left: 0;
	z-index: 2;
	box-shadow: 1px 0 1px var(--shadow), 1px 0 3px var(--shadow);

	.navigation-button {
		width: 100%;
		text-align: left;
		margin: 0 0 calc(var(--space) / 4) 0;
		white-space: nowrap;
		padding: 0 24px 0 0;
		display: flex;
		align-items: center;

		svg {
			margin: 8px 16px 8px 8px;
		}
	}

	.development {
		padding: var(--space);
		text-align: center;
		width: 100%;
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		color: var(--on-surface-variant);
	}

	.development:hover {
		background-color: var(--hover);
	}
}

.select-vendor-add-container {
	height: 100vh;
	width: 100vw;
	background-color: var(--elevation);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;

	.card {
		background-color: var(--background);
	}
}

//------------------------------------------------------------------------------------

@media only screen and (max-width: 600px) {
	.side-navigation-elevation {
		height: 100vh;
		width: 100vw;
		position: fixed;
		top: 0;
		left: 0;
		background-color: var(--elevation);
		z-index: 3;
	}

	.side-navigation {
		height: 100vh;
		width: calc(100vw - 64px);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 4;
		padding: calc(var(--space) * 2) var(--space);

		.navigation-button {
			margin: 0 0 calc(var(--space) / 2) 0;
		}
	}

	.side-navigation-handler {
		height: 64px;
		width: 100vw;
		padding: 0 var(--space) 0 var(--space);
		display: flex;
		align-items: center;

		.title {
			padding: 0 0 0 var(--space);
			color: var(--on-surface-variant);
		}
	}
}

//------------------------------------------------------------------------------------
