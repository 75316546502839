//------------------------------------------------------------------------------------

@keyframes fade-in {
	from {
		opacity: 0;
	}
}

//------------------------------------------------------------------------------------

@keyframes banner-fade {
	0% {
		filter: blur(20px);
		opacity: 0;
	}

	30% {
		filter: blur(0);
		opacity: 1;
	}

	70% {
		filter: blur(0);
		opacity: 1;
	}

	100% {
		filter: blur(20px);
		opacity: 0;
	}
}

//------------------------------------------------------------------------------------

@keyframes buffer {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

//------------------------------------------------------------------------------------

@keyframes slide-forward {
	from {
		transform: scale(0.5);
		opacity: 0;
	}
}

//------------------------------------------------------------------------------------

@keyframes slide-up {
	from {
		transform: translateY(var(--space));
		opacity: 0;
	}
}

@keyframes slide-down {
	to {
		transform: translateY(var(--space));
		opacity: 0;
	}
}

//------------------------------------------------------------------------------------

@keyframes scaleton-loading {
	100% {
		background-position: -200%;
	}
}

//------------------------------------------------------------------------------------

@keyframes rotate {
	from {
		rotate: -360deg;
	}
}
