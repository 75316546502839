//------------------------------------------------------------------------------------

.not-found-screen {
	height: 100vh;
	width: 100vw;
	background-color: var(--background);
	display: grid;
	place-content: center;

	.not-found-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: var(--space);

		.title {
			color: var(--on-surface);
		}

		.text {
			color: var(--on-surface-variant);
		}
	}
}

//------------------------------------------------------------------------------------
