//------------------------------------------------------------------------------------

.online-status {
    height: 56px;
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: var(--secondary);
    color: var(--on-secondary);
    z-index: 20;
    display: grid;
    place-content: center;
    animation: slide-up 300ms ease;
    box-shadow: 0 -1px 3px var(--shadow), 0 -1px 6px var(--shadow);
}

.online-status.online {
    background-color: var(--success);
    color: var(--on-success);
}

.online-status.offline {
    background-color: var(--error);
    color: var(--on-error);
}

//------------------------------------------------------------------------------------