//------------------------------------------------------------------------------------

.light-theme {
	--primary: #005ac1;
	--on-primary: #ffffff;
	--primary-container: #d8e2ff;
	--on-primary-container: #001a41;

	--secondary: #575e71;
	--on-secondary: #ffffff;
	--secondary-container: #dbe2f9;
	--on-secondary-container: #141b2c;

	--tertiary: #715573;
	--on-tertiary: #ffffff;
	--tertiary-container: #fbd7fc;
	--on-tertiary-container: #29132d;

	--warning: #7a5900;
	--on-warning: #ffffff;
	--warning-container: #ffdea3;
	--on-warning-container: #261900;

	--success: #006d3a;
	--on-success: #ffffff;
	--success-container: #82faab;
	--on-success-container: #00210e;

	--error: #ba1a1a;
	--error-container: #ffdad6;
	--on-error: #ffffff;
	--on-error-container: #410002;

	--background: #fefbff;
	--on-background: #1b1b1f;

	--surface: #f1f3fc;
	--on-surface: #1b1b1f;
	--surface-variant: #e1e2ec;
	--on-surface-variant: #44474f;

	--outline: #74777f;

	--hover: #0000000d;
	--shadow: #00000033;

	--disabled: #1c1b1f1f;
	--on-disabled: #1c1b1f66;

	--elevation: #1b1b1f66;
}

//------------------------------------------------------------------------------------

.dark-theme {
	--primary: #adc6ff;
	--on-primary: #002e69;
	--primary-container: #004494;
	--on-primary-container: #d8e2ff;

	--secondary: #bfc6dc;
	--on-secondary: #293041;
	--secondary-container: #3f4759;
	--on-secondary-container: #dbe2f9;

	--tertiary: #debcdf;
	--on-tertiary: #402843;
	--tertiary-container: #583e5b;
	--on-tertiary-container: #fbd7fc;

	--warning: #fdbc13;
	--on-warning: #402d00;
	--warning-container: #5d4200;
	--on-warning-container: #ffdea3;

	--success: #64dd91;
	--on-success: #00391c;
	--success-container: #00522b;
	--on-success-container: #82faab;

	--error: #ffb4ab;
	--error-container: #93000a;
	--on-error: #690005;
	--on-error-container: #ffdad6;

	--background: #1b1b1f;
	--on-background: #e3e2e6;

	--surface: #2a2c36;
	--on-surface: #e3e2e6;
	--surface-variant: #44474f;
	--on-surface-variant: #c4c6d0;

	--outline: #8e9099;

	--hover: #ffffff0d;
	--shadow: #00000033;

	--disabled: #e3e2e61f;
	--on-disabled: #e3e2e666;

	--elevation: #1b1b1f66;
}

//------------------------------------------------------------------------------------
