//------------------------------------------------------------------------------------

.footer-container {
	padding: var(--space);
	background-color: var(--hover);
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: var(--space) 0 0 0;
	border-radius: 12px;

	.firm-name {
		color: var(--on-surface);
		text-align: center;

		a {
			color: var(--on-surface);
			text-decoration: underline;
		}
	}

	.copyrights {
		color: var(--on-surface-variant);
		text-align: center;
		// margin: var(--space) 0 0 0;
	}

	.link {
		color: var(--on-surface-variant);
		text-align: center;
		margin: 16px 0 0 0;
	}
}

//------------------------------------------------------------------------------------
