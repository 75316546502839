//------------------------------------------------------------------------------------

.screen-container {
	margin: 64px 0 0 0;

	.side-container {
		display: flex;
		justify-content: space-between;

		.side-content {
			width: calc(100vw - (var(--space) + 260px));
			// flex: 1;
			padding: var(--space) var(--space) var(--space) 0;
			animation: slide-up 300ms ease;
			position: relative;

			.screen-title {
				color: var(--on-surface);
				padding: 0 0 var(--space) 0;
			}
		}
	}

	.label {
		color: var(--primary);
		padding: var(--space) 0 calc(var(--space) / 4) 0;

		button {
			height: 28px;
			width: 28px;

			svg {
				height: 16px;
				width: 16px;
			}
		}
	}
}

.refreshing {
	animation: rotate 600ms linear infinite;
}

.search-container {
	height: 48px;
	width: 100%;
	border-radius: 24px;
	background-color: var(--background);
	margin: 0 0 var(--space) 0;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 4px;
	gap: 4px;
	position: sticky;
	top: calc(64px + var(--space));
	z-index: 2;
	box-shadow: 0 1px 1px var(--shadow), 0 1px 3px var(--shadow);
	border-radius: 24px;

	input {
		height: 40px;
		flex: 1;
		border: none;
		outline: none;
		padding: 16px;
		background-color: unset;
		color: var(--on-surface-variant);
		border-radius: 20px;
	}

	input:focus {
		background-color: var(--background);
		// box-shadow: 0 0 0 2px var(--primary);
		box-shadow: 0 0 0 2px var(--background), 0 0 0 4px var(--primary);
	}
}

.dialog-container {
	height: 100vh;
	width: 100vw;
	background-color: var(--elevation);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10 !important;
	display: grid;
	place-content: center;
	animation: fade-in 300ms ease;
	backdrop-filter: blur(var(--blur));

	.dialog {
		max-height: calc(100vh - (var(--space) * 2));
		width: 650px;
		max-width: calc(100vw - (var(--space) * 2));
		background-color: var(--background);
		border-radius: 16px;
		box-shadow: 0 1px 3px var(--shadow), 0 6px 12px var(--shadow);
		overflow: auto;
		position: relative;
		animation: slide-forward 300ms ease;

		.dialog-top {
			height: 64px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 8px 0 var(--space);
			background-color: var(--surface);
			position: sticky;
			top: 0;
			left: 0;
			box-shadow: 0 1px 1px var(--shadow), 0 1px 3px var(--shadow);

			.title {
				color: var(--on-surface-variant);
			}
		}

		.label {
			color: var(--primary);
			padding: var(--space) 0 calc(var(--space) / 4) 0;
		}

		.dialog-content {
			padding: var(--space);

			.img {
				height: 100%;
				width: 100%;
			}

			.container-1 {
				display: flex;
				justify-content: space-between;
				margin: auto;

				.profile-image {
					width: 30%;
					aspect-ratio: 3 / 4;
					background-color: var(--hover);
					box-shadow: 0 1px 1px var(--shadow), 0 1px 3px var(--shadow);
				}

				.id-image {
					width: 70%;
					aspect-ratio: 7 / 4;
					background-color: var(--hover);
					margin: 0 0 0 var(--space);
					box-shadow: 0 1px 1px var(--shadow), 0 1px 3px var(--shadow);
				}
			}

			.container-2 {
				display: flex;
				justify-content: space-between;
				margin: auto;
				margin: var(--space) auto 0 auto;

				.shop-image,
				.sign-image,
				.stamp-image {
					width: 30%;
					aspect-ratio: 1 / 1;
					box-shadow: 0 1px 1px var(--shadow), 0 1px 3px var(--shadow);
					background-color: var(--hover);
				}
			}

			.container-3 {
				margin: auto;

				.details-container-1 {
					display: flex;
					align-items: center;
					justify-content: space-between;

					> :nth-child(1),
					> :nth-child(2),
					> :nth-child(3) {
						width: 33.33%;
					}
				}
			}
		}
	}

	.dialog::-webkit-scrollbar {
		display: block !important;
	}
}

//------------------------------------------------------------------------------------

@media only screen and (max-width: 600px) {
	.screen-container {
		.side-container {
			display: block;

			.side-content {
				width: 100vw;
				padding: var(--space);
			}
		}
	}

	.dialog-container {
		.dialog {
			.dialog-content {
				.container-1 {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;

					.profile-image {
						width: 40%;
					}

					.id-image {
						margin: var(--space) 0 0 0;
					}
				}

				.container-2 {
					width: 100%;

					.shop-image,
					.sign-image,
					.stamp-image {
						width: 30%;
						aspect-ratio: 1 / 1;
						background-color: var(--hover);
					}
				}

				.container-3 {
					width: 100%;
					padding: var(--space);

					.details-container-1 {
						display: block;

						> :nth-child(1),
						> :nth-child(2),
						> :nth-child(3) {
							width: 100%;
						}
					}
				}
			}
		}
	}
}

//------------------------------------------------------------------------------------
