//------------------------------------------------------------------------------------


.display-large {
    font-weight: 400;
    font-size: 57px;
    line-height: 64px;
    letter-spacing: -0.25px;
}

.display-medium {
    font-weight: 400;
    font-size: 45px;
    line-height: 52px;
    letter-spacing: 0px;
}

.display-small {
    font-weight: 400;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0px;
}

//------------------------------------------------------------------------------------


.headline-large {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0px;
}

.headline-medium {
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0px;
}

.headline-small {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0px;
}

//------------------------------------------------------------------------------------


.body-large {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.50px;
}

.body-medium {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
}

.body-small {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.40px;
}

//------------------------------------------------------------------------------------


.label-large {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.10px;
}

.label-medium {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.50px;
}

.label-small {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.50px;
}

//------------------------------------------------------------------------------------


.title-large {
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0px;
}

.title-medium {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
}

.title-small {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.10px;
}

//------------------------------------------------------------------------------------