//------------------------------------------------------------------------------------

.check-uncheck-container {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: grid;
    place-content: center;
}

.check-uncheck-container.checked {
    background-color: var(--success);

    svg {
        fill: var(--on-success);
    }
}

.check-uncheck-container.unchecked {
    background-color: var(--error);

    svg {
        fill: var(--on-error);
    }
}

//------------------------------------------------------------------------------------