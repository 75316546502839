//------------------------------------------------------------------------------------

.img {
	height: 100%;
	width: 100%;
}

.greeting-title {
	color: var(--on-surface-variant);
	width: calc(540px + var(--space));
	margin: auto;
	padding: 0 0 var(--space) 0;
}

img {
	background-color: var(--hover);
	border: none;
}

.container-1 {
	width: calc(540px + var(--space));
	display: flex;
	justify-content: space-between;
	margin: auto;

	.profile-image {
		width: 30%;
		aspect-ratio: 3 / 4;
	}

	.id-image {
		width: 70%;
		aspect-ratio: 7 / 4;
		margin: 0 0 0 var(--space);
	}

	img {
		box-shadow: 0 1px 1px var(--shadow), 0 1px 3px var(--shadow);
	}
}

.container-2 {
	width: calc(540px + var(--space));
	display: flex;
	justify-content: space-between;
	margin: auto;
	margin: var(--space) auto 0 auto;

	.shop-image,
	.sign-image,
	.stamp-image {
		width: 30%;
		aspect-ratio: 1 / 1;
	}

	img {
		box-shadow: 0 1px 1px var(--shadow), 0 1px 3px var(--shadow);
	}
}

.warning-container {
	width: calc(540px + var(--space));
	background-color: var(--error-container);
	color: var(--on-error-container);
	margin: var(--space) auto auto auto;
	padding: 16px;
	border-radius: 8px;
}

.container-3 {
	width: calc(540px + var(--space));
	margin: auto;

	.details-container-1 {
		display: flex;
		align-items: center;
		justify-content: space-between;

		> :nth-child(1),
		> :nth-child(2),
		> :nth-child(3) {
			width: 33.33%;
		}
	}

	.title-large,
	.body-large {
		color: var(--on-surface-variant);
	}
}

//------------------------------------------------------------------------------------

@media only screen and (max-width: 840px) {
	.greeting-title,
	.container-1,
	.container-2,
	.container-3,
	.warning-container {
		width: 440px;
	}
}

//------------------------------------------------------------------------------------

@media only screen and (max-width: 600px) {
	.greeting-title {
		width: 100%;
		padding: 0 0 0 var(--space);
	}

	.container-1 {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		.profile-image {
			width: calc(40% - 36px);
		}

		.id-image {
			margin: var(--space) 0 0 0;
			padding: var(--space) 0 0 var(--space);
		}
	}

	.container-2 {
		width: 100%;
		padding: var(--space) 0 0 var(--space);

		.shop-image,
		.sign-image,
		.stamp-image {
			width: 30%;
		}
	}

	.warning-container {
		width: unset;
		margin: var(--space) 0 0 var(--space);
	}

	.container-3 {
		width: 100%;
		padding: var(--space);

		.details-container-1 {
			display: block;

			> :nth-child(1),
			> :nth-child(2),
			> :nth-child(3) {
				width: 100%;
			}
		}
	}
}

//------------------------------------------------------------------------------------
