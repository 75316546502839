//------------------------------------------------------------------------------------

.active-vendors-counts-container {
	display: flex;
	gap: var(--space);
	padding: 0 0 var(--space) 0;

	.active-vendors-counts {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 33%;
		background-color: var(--surface-variant);
		padding: var(--space);
		border-radius: 12px;

		.value {
			color: var(--on-surface-variant);
			margin: 0 0 16px 0;

			span {
				opacity: 0.5;
			}
		}

		.title {
			color: var(--on-surface);
		}
	}

	.total {
		background-color: var(--secondary);

		.value {
			color: var(--on-secondary);
		}

		.title {
			color: var(--background);
		}
	}
}

.vendors-table {
	width: 100%;
	overflow: auto;
	padding: 3px 3px var(--space) 3px;

	.img {
		height: 100%;
		width: 100%;
	}

	// table {
	//     width: fit-content;
	//     margin: auto;
	//     border-radius: 16px;
	//     overflow: hidden;
	//     box-shadow: 0 0 0 2px var(--background), 0 0 0 3px var(--outline);

	//     tr {
	//         // background-color: var(--surface);

	//         th {
	//             // padding: 8px;
	//             // text-align: left;
	//             // border: 1px solid var(--outline);
	//             // color: var(--on-secondary-container);
	//             // font-weight: 500;
	//             // font-size: 12px;
	//             // line-height: 20px;
	//             // letter-spacing: 0.15px;
	//             // background-color: var(--secondary-container);
	//             // white-space: nowrap;
	//             // // box-shadow: inset 0 0 0 2px var(--background);
	//             // text-align: center;

	//             height: 56px;
	//             max-width: 400px;
	//             padding: 8px;
	//             // border: 1px solid var(--hover);
	//             // border: 1px solid var(--outline);
	//             background-color: var(--secondary-container);
	//             color: var(--on-secondary-container);
	//             font-weight: 500;
	//             font-size: 12px;
	//             line-height: 20px;
	//             letter-spacing: 0.15px;
	//             text-align: center;
	//             border-right: 1px solid var(--background);
	//             border-left: 1px solid var(--background);
	//             border-bottom: 1px solid var(--outline);
	//         }

	//         td {
	//             height: 41px !important;
	//             padding: 0 8px;
	//             // border: 1px solid var(--outline);
	//             color: var(--on-surface);
	//             font-weight: 400;
	//             font-size: 12px;
	//             line-height: 20px;
	//             letter-spacing: 0.5px;
	//             cursor: pointer;
	//             white-space: nowrap;
	//             // box-shadow: inset 0 0 0 2px var(--background);

	//             border-right: 1px solid var(--background);
	//             border-left: 1px solid var(--background);
	//             border-top: 1px solid var(--outline);

	//             button.text-button {
	//                 padding: 0;
	//             }

	//             * {
	//                 font-size: 12px;
	//             }
	//         }

	//         .center {
	//             text-align: center;
	//         }

	//         .border-left-none {
	//             border-left: unset !important;
	//         }
	//     }

	//     tr.disabled-row {
	//         pointer-events: none !important;
	//         opacity: 0.4;

	//         * {
	//             text-decoration: line-through;
	//         }
	//     }

	//     tr.success-row {
	//         background-color: var(--success-container) !important;
	//     }

	//     .success-background {
	//         background-color: var(--success-container) !important;
	//         // box-shadow: inset 0 0 0 2px var(--background);
	//     }

	//     .error-background {
	//         background-color: var(--error-container) !important;
	//         // box-shadow: inset 0 0 0 2px var(--background);
	//     }

	//     .approval-status {
	//         display: flex;
	//         align-items: center;
	//         gap: 8px;
	//         color: var(--on-surface-variant);
	//     }

	//     .always-active {
	//         pointer-events: all !important;
	//         opacity: 1 !important;

	//         * {
	//             text-decoration: unset !important;
	//             opacity: 1 !important;
	//         }
	//     }

	//     tr:hover {
	//         box-shadow: inset 0 0 0 40px var(--hover);
	//     }
	// }

	table {
		width: fit-content;
		background-color: var(--background);
		overflow: hidden;

		tr {
			th {
				height: 56px;
				max-width: 400px;
				padding: 8px;
				background-color: var(--secondary-container);
				text-align: center;
				border-right: 1px solid var(--surface);
				border-left: 1px solid var(--surface);
				overflow: hidden;
				color: var(--on-secondary-container);
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
				letter-spacing: 0.15px;
				text-align: center;
				white-space: nowrap;

				* {
					svg {
						fill: var(--on-disabled);
					}
				}
			}

			.left-box {
				border-radius: 8px 0 0 8px !important;
			}

			.right-box {
				border-radius: 0 8px 8px 0 !important;
			}

			td {
				height: 40px !important;
				// max-width: 400px;
				padding: 0 8px;
				border-right: 1px solid var(--background);
				border-left: 1px solid var(--background);
				color: var(--on-surface);
				font-weight: 400;
				font-size: 12px;
				line-height: 20px;
				letter-spacing: 0.5px;
				cursor: pointer;
				height: 2em;
				white-space: nowrap;
				overflow: hidden;
				background-color: var(--surface) !important;

				.table-profile-photo {
					height: 30px;
					width: 30px;
					display: grid;
					place-content: center;
					background-color: var(--hover);
					border-radius: 50%;
					overflow: hidden;

					img {
						width: 100%;
					}
				}

				* {
					font-size: 12px;
				}

				button.text-button {
					padding: 0;
				}

				.success {
					color: var(--primary);
					text-align: center;
				}

				.warning {
					color: var(--error);
					text-align: center;
				}

				.qr-image {
					height: 200px;
					width: 200px;
				}
			}

			.center {
				text-align: center !important;
			}

			td {
				.display-center {
					display: grid;
					place-content: center;
					padding: 4px 0;
				}
			}

			td.diff-1 {
				background-color: var(--hover);
			}

			td.img {
				height: fit-content !important;

				img {
					height: 100%;
					width: 100%;
				}
			}
		}

		.gap {
			height: 4px;
			width: 100%;
		}

		.success-background {
			background-color: var(--success-container) !important;
		}

		.error-background {
			background-color: var(--error-container) !important;
		}

		.approval-status {
			display: flex;
			align-items: center;
			gap: 8px;
			color: var(--on-surface-variant);
		}

		tr.disabled-row {
			pointer-events: none !important;
			opacity: 0.4;

			* {
				text-decoration: line-through;
			}
		}

		tr.owner-disabled-row {
			background-color: var(--surface-variant);
			opacity: 0.4;
		}

		tr.deleted-row {
			pointer-events: none !important;
			opacity: 0.4;
			background-color: var(--error-container);

			* {
				text-decoration: line-through;
				color: var(--on-surface-variant) !important;
			}
		}

		tr.disable-row {
			background-color: var(--surface-variant);
		}

		tr.success-row {
			background-color: var(--success-container) !important;
		}

		tr.warning-row {
			background-color: var(--warning-container);
		}

		.dot {
			height: 12px;
			width: 12px;
			border-radius: 50%;
			background-color: var(--hover);
			display: inline-block;
			margin: 4px 8px 0 0;
		}

		.dot.success {
			background-color: var(--success);
		}

		.dot.error {
			background-color: var(--error);
		}
	}
}

.vendors-table::-webkit-scrollbar {
	display: block !important;
}

.warning-container {
	border-radius: 8px;
	background-color: var(--warning-container);
	color: var(--on-warning-container);
	padding: calc(var(--space) / 1.5);
	box-shadow: inset 0 0 0 2px var(--surface);
	outline: 2px solid var(--warning);
}

.warning-container.strong {
	background-color: var(--error);
	color: var(--on-error);
	box-shadow: inset 0 0 0 2px var(--surface);
	outline: 2px solid var(--error);
}

//------------------------------------------------------------------------------------

@media only screen and (max-width: 600px) {
	.active-vendors-counts-container {
		flex-direction: column;

		.active-vendors-counts {
			width: 100% !important;
		}
	}
}

//------------------------------------------------------------------------------------

@media (hover: hover) {
	.hover:hover {
		background-color: var(--hover);
		box-shadow: inset 0 0 0 2px var(--primary);
	}

	.open-dialog:hover {
		background-color: var(--primary);
		color: var(--on-primary) !important;
	}
}

//------------------------------------------------------------------------------------

@media (hover: none) {
	.hover:active {
		background-color: var(--hover);
		box-shadow: inset 0 0 0 2px var(--primary);
	}

	.open-dialog:active {
		background-color: var(--primary);
		color: var(--on-primary) !important;
	}
}

//------------------------------------------------------------------------------------
