//------------------------------------------------------------------------------------

.loading-screen {
	height: 100vh;
	width: 100vw;
	background-color: var(--elevation);
	position: fixed;
	top: 0;
	left: 0;
	pointer-events: none;
	cursor: progress;
	z-index: 100;
	display: grid;
	place-content: center;
	backdrop-filter: blur(var(--blur));
	z-index: 10 !important;
	animation: fade-in 300ms ease;

	.laoder-container {
		width: min(400px, calc(100vw - (var(--space) * 2)));
		display: grid;
		place-content: center;
		background-color: var(--background);
		padding: 32px;
		border-radius: 16px;
		box-shadow: 0 3px 6px var(--shadow), inset 0 0 0 8px var(--surface);
		animation: slide-forward 300ms ease;

		.loader {
			display: inline-block;
			position: relative;
			width: 80px;
			height: 80px;
			margin: auto;

			div {
				box-sizing: border-box;
				display: block;
				position: absolute;
				width: 56px;
				height: 56px;
				margin: 8px;
				border: 4px solid var(--primary);
				border-radius: 50%;
				animation: buffer 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
				border-color: var(--primary) transparent transparent transparent;
			}

			div:nth-child(1) {
				animation-delay: -0.45s;
			}

			div:nth-child(2) {
				animation-delay: -0.3s;
			}

			div:nth-child(3) {
				animation-delay: -0.15s;
			}
		}
	}

	.text {
		color: var(--on-surface-variant);
		text-align: center;
		margin: var(--space) 0 0 0;
	}

	//------------------------------------------------------------------------------------
}

//------------------------------------------------------------------------------------
