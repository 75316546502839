//------------------------------------------------------------------------------------

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

//------------------------------------------------------------------------------------

html {
    background-color: var(--background);

    body {
        margin: 0;
        background-color: var(--background);
        animation: fade-in var(--transition-large) ease;
        display: none;

        --transition-small: 150ms;
        --transition-medium: 300ms;
        --transition-large: 600ms;

        --space: 32px;

        --blur: 8px;
    }
    * {
        font-family: 'Poppins', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        box-sizing: border-box;
        transition: var(--transition-medium) ease;
        scroll-behavior: smooth;
    }
    ::placeholder {
        color: var(--on-surface-variant);
    }

    a {
        text-decoration: none;
        color: var(--primary);
    }

    svg {
        * {
            transition: none;
        }
    }

    input {
        color: var(--on-surface) !important;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }

    .hidden {
        display: none;
    }

    option {
        background-color: var(--surface);
        color: var(--on-surface);
    }

    ::-webkit-scrollbar {
        height: 12px;
        width: 12px;
        overflow: hidden;
        border-radius: 8px;
    }

    ::-webkit-scrollbar-track {
        background: var(--hover);
        border-radius: 8px;
        overflow: hidden;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: var(--hover);
    }

    ::-webkit-scrollbar-thumb:hover {
        background: var(--disabled);
    }

    table {
        border-collapse: collapse;
    }

    img {
        display: block;
        background-image: linear-gradient(
            90deg,
            var(--background) 0,
            var(--background) 20%,
            var(--hover) 60%,
            var(--background)
        );
        background-size: 200%;
        background-position-x: 200%;
        animation: scaleton-loading calc(var(--transition-large) * 2) ease
            infinite;
    }

    ::placeholder {
        color: var(--on-disabled);
    }

    dialog {
        padding: 0;
        outline: none;
        border: none;
    }

    mark {
        background: var(--warning) !important;
        color: var(--on-warning) !important;
    }
}

//------------------------------------------------------------------------------------

@media only screen and (max-width: 840px) {
    html {
        body {
            --space: 24px;
        }
    }
}

//------------------------------------------------------------------------------------

@media only screen and (max-width: 600px) {
    html {
        body {
            --space: 16px;
        }
    }
}

//------------------------------------------------------------------------------------

.light-theme {
    * {
        color-scheme: light;
    }
}

.dark-theme {
    * {
        color-scheme: dark;
    }
}

//------------------------------------------------------------------------------------
